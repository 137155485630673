import './App.css';
import KnownUsers from './KnownUsers';
import AddUser from './AddUser';
import UserDetail from './UserDetail';
import Signin from './Signin';
import CreateTransaction from './CreateTransaction';
import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import api from './api';
import {
  Switch,
  Route
} from "react-router-dom";

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function checkSignIn() {
      const isSignedIn = await api.isSignedIn();
      if (isSignedIn) {
	setIsSignedIn(true);
      }
      setIsLoading(false);
    }
    
    checkSignIn();
  }, []);

  return (
    isLoading
    ? 
      <Box fullWidth sx={{
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh'
      }}>
	<CircularProgress />
      </Box>
    :
    ( !isSignedIn ?
      <Signin setSignedIn={setIsSignedIn}/>
      :
      <Switch>
	<Route path="/knownUsers">
	  <KnownUsers />
	</Route>
	<Route path="/addUser">
	  <AddUser />
	</Route>
	<Route path="/userDetail/:id">
	  <UserDetail />
	</Route>
	<Route path="/transactions/create">
	  <CreateTransaction />
	</Route>
	<Route path="/signin">
	  <Signin />
	</Route>
	<Route path="/">
	  <KnownUsers />
	</Route>
      </Switch>
    )
  );
}

export default App;
