import api from './api'
import { Link } from 'react-router-dom';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemAvatar,
  TextField
} from '@mui/material';
import { useState } from 'react';

function AddUser() {
  const [searchString, setSearchString] = useState('');
  const [users, setUsers] = useState([]);

  const handleChange = async (event) => {
    const value = event.target.value;
    console.log(value);
    setSearchString(value);
    
    if (value.length >= 1) {
      const searchResults = await api.searchForUser(value.toLowerCase());
      console.log(searchResults);
      setUsers(searchResults);
    }
    else setUsers([]);
  };
  
  return (
    <>
      <TextField
	label="Suche"
	onChange={handleChange}
	value={searchString}
	variant="outlined"
	sx={{
	  m: 4,
	  display: 'flex'
	}}
      />
      <List>
	{ users.map(u =>
	<ListItemButton
	  component={Link}
	  key={u.id}
	  to={{
	    pathname:`/transactions/create`,
	    search: `?targetUserId=${u.id}`,
	    state: { targetUserId: u.id, targetUserName: u.name }
	  }}
	>
	  <ListItemAvatar>
	  <Avatar alt={u.name} src="#" />
	  </ListItemAvatar>
	  {u.name}
	</ListItemButton>
	)}
      </List>
    </>
  );
}

export default AddUser;
