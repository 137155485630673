import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from './api';

function CreateTransaction() {
  const { targetUserId, targetUserName } = useLocation().state;
  const [transactionType, setTransactionType] = useState('payment'); // claim
  const [amount, setAmount] = useState("");
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [message, setMessage] = useState("");
  const history = useHistory();

  function validateAndSetAmount(event) {
    const amount = event.target.value;
    const amountRegex = /\d+[,.]\d+/;

    setIsAmountValid(amountRegex.test(amount));

    setAmount(amount);
  }

  const handleTransactionType = (event, newType) => {
    if (newType === null) return;
    setTransactionType(newType);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    if (!isAmountValid)
      return;

    const response = await api.createTransaction(
	targetUserId,
	transactionType,
	amount,
	message);

    console.log(response);

    if (response.status === 200)
      history.goBack();
    else console.log('TODO: Show error');
  };

  return (
    <div
      className="space-y-5"
      style={{
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: '5rem'
      }}
      >
      <ToggleButtonGroup
	value={transactionType}
	exclusive
	onChange={handleTransactionType}
	aria-label="transaction type"
      >
	<ToggleButton value="payment">
	  Zahlung
	</ToggleButton>
	<ToggleButton value="claim">
	  Forderung
	</ToggleButton>
      </ToggleButtonGroup>
      <p>an {targetUserName}</p>
      <TextField error={!isAmountValid} value={amount} label="Betrag" variant="standard" onChange={validateAndSetAmount}>
      </TextField>
      <TextField
	sx={{marginTop: '1.25rem'}}
      label="Nachricht"
      variant="standard"
      onChange={handleMessageChange} />
      <Button
	onClick={handleSend}
	sx={{marginTop: '1.25rem'}}
      >
	Senden
      </Button>
    </div>
  );
}

export default CreateTransaction;
