import { createBrowserHistory } from 'history';

class Api {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    this.history = createBrowserHistory();
  }

  baseFetch(url, options) {
    if (!options)
      options = { credentials: 'include' };
    else options.credentials = 'include';

    return fetch(`${this.baseUrl}${url}`, options);
  }

  async searchForUser(searchString) {
    const res = await this.baseFetch(`/users/search?q=${searchString}`);
    return await res.json();
  }

  async isSignedIn() {
    const res = await this.baseFetch('/signin');
    const json = await res.json();

    return json.signedIn === true;
  }

  signIn(email, password) {
    return this.baseFetch('/signin', {
      method: 'POST',
      headers: {
	'Content-Type': 'application/json'
      },
      body: JSON.stringify({
	email: email,
	password: password
      })
    });
  }

  async getTransactions(userId) {
    console.log('fetching transactions');
    const res = await this.baseFetch(`/transactions?userId=${userId}`,
     { credentials: 'include' });
    const jsonRes = await res.json();

    return jsonRes;
  }

  async getKnownUsers() {
    let apiRes = await this.baseFetch('/users/known');
    if (apiRes.status === 401) {
      this.history.replace('/signin');
      return;
    }
    let users = await apiRes.json();
    return users;
  }

  async createTransaction(targetUserId, transactionType, amount, message) {
    const response = await this.baseFetch('/transaction/create', {
      method: 'POST',
      headers: {
	'Content-Type': 'application/json'
      },
      body: JSON.stringify({
	targetUserId: targetUserId,
	type: transactionType,
	amount: amount,
	payload : message
      })
    });

    return response;
  }
}

const api = new Api();

export default api;
