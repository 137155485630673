import api from './api';
import { useHistory } from 'react-router-dom';
//import { useEffect } from 'react';
import {
  Avatar,
  Typography,
  Button,
  TextField,
  Container,
  Box
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function Signin(props) {
  const history = useHistory();
  console.log({history});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const res = await api.signIn(data.get('email'), data.get('password'));
    if (res.status === 200) {
      history.replace('/knownUsers');
      if (props.setSignedIn)
	props.setSignedIn(true);
    }
    else {
      console.log('signin failed');
      console.log({res});
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
	<Box
	  sx={{
	    marginTop: 8,
	    display: 'flex',
	    flexDirection: 'column',
	    alignItems: 'center',
	  }}>
	  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
	    <LockOutlinedIcon />
	  </Avatar>
	  <Typography component="h1" variant="h5">
	    Anmelden
	  </Typography>
	  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
	    <TextField
	      margin="normal"
	      id="email"
	      required
	      fullWidth
	      label="Email Adresse"
	      name="email"
	      autoComplete="email"
	      autoFocus
	    />
	    <TextField
	      margin="normal"
	      required
	      fullWidth
	      name="password"
	      label="Passwort"
	      type="password"
	      id="password"
	      autoComplete="current-password"
	    />
	    <Button
	      type="submit"
	      fullWidth
	      variant="contained"
	      sx={{ mt: 3, mb: 2 }}
	    >
	      Anmelden
	    </Button>
	  </Box>
	</Box>
      </Container>
    </>
  )
}

export default Signin;
