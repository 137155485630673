import { useState, useEffect } from 'react';
import api from './api';
import {
  List,
  Divider,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Fab,
  Box
} from '@mui/material';
import {
  Link,
  useHistory
} from "react-router-dom";
import { Add as AddIcon } from '@mui/icons-material';

function KnownUsers() {
  const [knownUsers, setKnownUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const users = await api.getKnownUsers();
      console.log(users);
      const dateFormatOptions
	= { year: 'numeric', month: 'numeric', day: 'numeric' };
      users.forEach(u => 
	u.latestTransactionDateString = new Date(u.latest_transaction_date).toLocaleDateString('de-DE', dateFormatOptions));
      setKnownUsers(users);
      setIsLoading(false);
    }
    fetchData();
  }, [history]);

  return (
    <>
      <Box sx={{
	bgcolor: 'primary.dark', p: 2,
	display: 'flex', alignItems: 'center',
      }}>
	<h2
	  style={{
	    color: 'white',
	    fontSize: '1.5rem',
	    lineHeight: '2rem'
	  }}
	  >Kontakte</h2>
      </Box>
      { isLoading ? <p>Loading...</p> :
      <div className="flex bg-gray-100 min-h-screen">
	<List sx={{ width: '100%', /*maxWidth: 360, */bgcolor: 'background.paper' }}>
	  { knownUsers.map(u =>
	  <div key={u.id}>
	    <ListItemButton
	      component={Link}
	      alignItems="flex-start"
	      to={{
		pathname: `/userDetail/${u.id}`,
		state: { userName: u.name }
	      }}
	    >
	      <ListItemAvatar>
		<Avatar alt={u.name} src="#"/>
	      </ListItemAvatar>
	      <ListItemText
		primary={u.name}
		secondary={`Letzte Transaktion am ${u.latestTransactionDateString}`} />
	      <p className={u.amount < 0.0 ? "text-red-500" : "text-green-500"}>
		{u.amount.replace('.', ',')} €
	      </p>
	    </ListItemButton>
	    <Divider variant="inset"/>
	  </div>
	  )}
	</List>
      </div> }
      <Fab
	component={Link}
	to={{
	  pathname:`/addUser`
	}}
	sx={{ position: 'absolute', bottom: 16, right: 16}}
	color="primary"
	aria-label="add">
	<AddIcon />
      </Fab>
    </>
  );
}

export default KnownUsers;
