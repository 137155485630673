import { useLocation, useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Fab, Avatar, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import api from './api';

function UserDetail() {
  const { id } = useParams();
  const { userName } = useLocation().state;
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.getTransactions(id);
      setTransactions(res);
      setIsLoading(false);
    }
    fetchData();
  }, [id]);

  const viewData = transactions.map(t => {
    let result = {}
    result.headline = t.amount >= 0.0
      ? "Zahlung"
      : "Forderung";
    
    if (t.by_me) {
      result.headline += " von dir";
    } else {
      result.headline += " an dich";
    }
    const amount = t.by_me ? -t.amount : t.amount;
    result.isAmountNegative = amount < 0.0;
    result.amount = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
    result.payload = t.payload;

    const dateFormatOptions
      = { year: 'numeric', month: 'numeric', day: 'numeric' };

    result.date = new Date(t.created_at).toLocaleDateString('de-DE', dateFormatOptions);

    return result;
  });
  console.log(viewData);

    return (
      <>
	<Box sx={{
	  bgcolor: 'primary.dark', p: 2,
	  display: 'flex', alignItems: 'center',
	  height: '6vh'
	  }}>
	  <Avatar alt={userName} src="#"/>
	  <h2
	    style={{
	      fontSize: '1.5rem',
	      lineHeight: '2rem',
	      color: 'white',
	      marginLeft: '1rem'
	    }}
	  >{userName}</h2>
	</Box>
	{ isLoading ? <p>Loading...</p> :
	  <div>
	    <List sx={{
	      overflow: 'auto',
	      maxHeight: '94vh',
	      padding: 0
	    }}>
	      { viewData.map((t, i) =>
	      <ListItem
		key={i}
		sx={{}}
	      >
		<ListItemText
		  primary={ t.headline }
		  secondary={ t.payload ?? "Keine Nachricht" }
		/>
		<ListItemText
		  sx={{
		    flex: 'initial',
		    display: 'flex',
		    flexDirection: 'column',
		    alignItems: 'flex-end'
		  }}
		  primaryTypographyProps={{
		    className: t.isAmountNegative ? "text-red-500" : "text-green-500"
		  }}
		  primary={t.amount.replace('.', ',')}
		  secondary={t.date} />

		{/*<div style={{ display: 'flex', flexDirection: 'column'}}>
		  <p 
		    style={{marginTop: '6px'}}
		    className={ t.isAmountNegative ? "text-red-500" : "text-green-500" }>
		    { t.amount.replace('.', ',') }
		  </p>
		  <p
		    style={{
		      color: 'rgb(156, 163, 175)',
		      fontSize: '0.875rem',
		      lineHeight: '1.25rem'
		    }}
		  >
		    { t.date }
		  </p>
		</div>*/}
	      </ListItem>)
	      }
	    </List>
	    <Fab
	      component={Link}
	      to={{
		pathname:`/transactions/create`,
		search: `?targetUserId=${id}`,
		state: { targetUserId: id, targetUserName: userName }
	      }}
	      sx={{ position: 'absolute', bottom: 16, right: 16}}
	      color="primary"
	      aria-label="add">
	      <AddIcon />
	    </Fab>
	  </div>
	}
	</>
    );
}

export default UserDetail;
